import React, { useState, useEffect, Fragment } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getData } from '../../../services/fetch-service';

function FotoBoxBallin(props) {
    const { layout, locale } = props;
    const [photoBox, setPhotoBox] = useState([]);
    const [page, setPage] = useState([]);
    const [url, setUrl] = useState();
    useEffect(() => {
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=1&Layout=${layout}&Gjuha=${locale}&take=1`).then((response) => {
            setPhotoBox(response.data.galeriaLista);
            setPage(response.data.page[0]);
            setUrl(`/PhotoGalery/${response.data.page[0].pageId}`);
        });
    }, []);
    const boxes = photoBox.map((item, key) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri}${item.media?.mediaEx}`;
        return (
            <Fragment>
                <Link to={url} key={key}>
                    <i className="far fa-images" />
                </Link>
                {/* <img src={item.media?.otherSource === null ? image : item.media?.otherSource} alt="" /> */}
                <LazyLoadImage
                    alt=""
                    effect="blur"
                    placeholderSrc="/images/blur/solid.jpg"
                    src={item.media?.otherSource === null ? image : item.media?.otherSource}
                />
            </Fragment>
        );
    });

    return (
        <Fragment>
            <div className="ch-thumb">
                {boxes}
            </div>
            <div className="ch-txt">
                <h5><Link to={url}>{page.pageName}</Link></h5>
            </div>
        </Fragment>
    );
}

FotoBoxBallin.propTypes = {
    layout: PropTypes.oneOf(['default']),
};

FotoBoxBallin.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FotoBoxBallin);
