import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import { getData } from '../../../services/fetch-service';
import PageHeader from '../../shared/PageHeader';

function PageSlider(props) {
    const params = useParams();
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [pageSlider, setPageSlider] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    useEffect(() => {
        getData(`Home/GetSlides?Gjuha=${locale}&PageID=${params.id === undefined ? 0 : params.id}`).then((response) => {
            setPageSlider(response.data[0]?.media);
        });
        if (params.id !== undefined) {
            getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
                setBreadcrumbs(response.data);
            });
        }
    }, [url]);
    const path = process.env.REACT_APP_FILESPATH;
    const image = pageSlider?.otherSource === null ? `${path}${pageSlider?.mediaEmri}${pageSlider?.mediaEx}` : pageSlider?.otherSource;
    return (
        <div className="row rreshtiii page-slider-section">
            <LazyLoadImage
                alt=""
                width="100%"
                className="PageSlider"
                placeholderSrc="/images/blur/slider.png"
                effect="blur"
                src={image}
            />
            <div className="breadcrumbs-slider">
                <PageHeader breadcrumb={breadcrumbs} />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PageSlider);
