// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { getData } from '../../../services/fetch-service';
import SkeletonMinistri from '../Skeletons/Skeleton-Ministri';

// application

function MinistriSidebar(props) {
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [socialLinks, setSocialLinks] = useState([]);
    const [personeli, setPersoneli] = useState([]);
    const [loader, setLoader] = useState(true);
    // const params = useParams();
    useEffect(() => {
        getData(`Personel/GetPersonels?PageID=1&Gjuha=${locale}&skip=0&take=1`).then((response) => {
            setPersoneli(response.data.result[0]);
            setLoader(false);
        })
        getData(`Home/GetSocialNetworks?Gjuha=${locale}&Layout=default&LlojiID=1`).then((response) => {
            setSocialLinks(response.data);
        })
    }, [url]);

    const socials = socialLinks.map((item, key) => (
        <li key={key} className="social-links__item">
            {item.html !== '' && item.html !== null ? (
                <a
                    className="social-links__link topbar-socials"
                    href={item.linku}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className={item.html} />
                </a>
            ) : (
                <a
                    className={`social-links__link topbar-socials social-links__link--type--${item.emertimi}`}
                    href={item.linku}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={`${item.imgPath}`} alt="" width="34px" height="34px" />
                </a>
            )}
        </li>
    ));

    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${personeli.media?.mediaEmri}${personeli.media?.mediaEx}`;

    return (
        <Fragment>
            {
                loader === true
                    ? (
                        <SkeletonMinistri />
                    )
                    : (
                        <Fade big>
                            <div className="Mayor-msg">
                                <Link to={`/PersonelDetails/6/${personeli.personeliId}`}>
                                    <div className="Mayor-thumb">
                                        {personeli.media?.mediaEmri !== undefined && (
                                            <LazyLoadImage
                                                alt=""
                                                width="100%"
                                                className="PageSlider"
                                                effect="blur"
                                                src={personeli.media === null ? '/images/default.jpg' : image}
                                            />
                                        )}
                                    </div>
                                </Link>
                                <div className="Mayor-text">
                                    <span>
                                        {personeli.personeliPozita}
                                    </span>
                                    <span>
                                        <Link to={`/PersonelDetails/6/${personeli.personeliId}`}>
                                            <h6>
                                                {personeli.personeliEmri}
                                                &nbsp;
                                                {personeli.personeliMbiemri}
                                            </h6>
                                        </Link>

                                    </span>
                                    <ul className="team-social">{socials}</ul>
                                </div>

                            </div>
                        </Fade>
                    )
            }
        </Fragment>
    );
}

MinistriSidebar.propTypes = {
    locale: PropTypes.string,
};

MinistriSidebar.defaultProps = {
    // layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(MinistriSidebar);
