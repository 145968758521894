// react
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// third-party
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';

function VideoGaleryDetailsPost(props) {
    const { layout } = props;
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const [galeria, setGaleria] = useState([]);
    const [Video, setVideoList] = useState([]);
    const path = process.env.REACT_APP_FILESPATH;
    useEffect(() => {
        getData(`Galery/GetGaleryDetails?MediaGaleriaID=${params.postimiId}`).then((response) => {
            setGaleria(response.data[0]);
            setVideoList(response.data[0].media)
        });
    }, [url]);
    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>

                <div>
                    <h1 className="post-header__title">
                        {galeria.mediaGaleriaPershkrimi}
                    </h1>
                </div>
                {
                    Video.map((video, key) => {
                        return (
                            <div className="video-details__box">
                                <div className="video-details" key={key} dangerouslySetInnerHTML={{ __html: video.otherSource === null ? `<video controls><source src="${path}${video.mediaEmri}${video.mediaEx}"></video>` : video.otherSource }} />
                            </div>
                        );
                    })
                }

            </div>
        </div>
    );
}

VideoGaleryDetailsPost.propTypes = {
    layout: PropTypes.oneOf(['classic', 'full']),
};
VideoGaleryDetailsPost.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(VideoGaleryDetailsPost);
