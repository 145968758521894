// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
import { useParams } from 'react-router-dom';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';

import { getData } from '../../../services/fetch-service';
import ProductGallery from '../../shared/ProductGallery';
import calendar from '../../../img/news/calendar.png';
import location from '../../../img/news/location.png';

function NewsDetailsPost(props) {
    const { layout } = props;
    const params = useParams();
    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });
    const editorClass = ['post__content typography'];
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    // const history = useHistory();
    const [News, setNews] = useState([]);
    const [Media, setMedia] = useState([]);
    const [textEditor, setTextEdtior] = useState('');
    const [MediaPostimet, setMediaPostimet] = useState([]);
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${Media?.mediaEmri}${Media?.mediaEx}`;
    const video = `<video controls><source src="${image}"></video>`

    useEffect(() => {
        getData(`News/GetNewsDetail?Gjuha=${locale}&PostimiID=${params.postimiId}`).then((response) => {
            setNews(response.data.postimi);
            setMedia(response.data.postimi.media);
            setMediaPostimet(response.data.mediaPostimi);
            setTextEdtior(response.data.postimi.postimiPermbajtja)
        }).catch(() => {
            window.location = '/404';
        });
    }, [url]);

    if (textEditor.includes('iframe')) {
        editorClass.push(' embed-responsive embed-responsive-16by9');
    }

    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>

                <div>
                    <div className="post-header__image">

                        {Media === null ? (
                            <img className="img-solid" src="/images/blur/news-solid.jpg" alt="" />
                        )
                            : (
                                <Fragment>
                                    {
                                        Media?.mediaExKategoriaId === 1 ? (
                                            <img
                                                alt=""
                                                effect="blur"
                                                loading="lazy"
                                                src={Media?.otherSource === null ? image : Media?.otherSource}
                                            />
                                        ) : (<div className="video-news embed-responsive embed-responsive-16by9" dangerouslySetInnerHTML={{ __html: Media?.otherSource === null ? video : Media?.otherSource }} />)
                                    }
                                </Fragment>

                            )}

                        <div className="post-topbar news-details-topbar">
                            <div className="post-date">
                                {News.postimiDataNgjarjes === null ? (
                                    <div className="post-card__date">{Moment(News.postimiDataFillimit).format('DD/MM/YYYY')}</div>
                                ) : (<div className="post-card__date">{Moment(News.postimiDataNgjarjes).format('DD/MM/YYYY')}</div>)}
                                <div className="post-card__icon"><img src={calendar} alt="" /></div>
                            </div>
                            {News.postimiLokacioni === '' ? ('') : (
                                <div className="post-location">
                                    <div className="post-card__date">{News.postimiLokacioni}</div>
                                    <div className="post-card__icon"><img src={location} alt="" /></div>
                                </div>
                            )}

                        </div>
                    </div>
                    <h1 className="post-header__title">
                        {News.postimiTitulli}
                    </h1>
                    <p
                        dangerouslySetInnerHTML={{ __html: News.postimiPershkrimi }}
                        className={postClasses}
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: News.postimiPermbajtja }}
                        className={editorClass.join('')}
                    />
                    <div className="news-socials">
                        <FacebookShareButton url={window.location.href}>
                            <FacebookIcon size={32} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton url={window.location.href}>
                            <FacebookMessengerIcon size={32} />
                        </FacebookMessengerShareButton>
                        <WhatsappShareButton url={window.location.href}>
                            <WhatsappIcon size={32} />
                        </WhatsappShareButton>
                        <ViberShareButton url={window.location.href}>
                            <ViberIcon size={32} />
                        </ViberShareButton>
                        <TwitterShareButton url={window.location.href}>
                            <TwitterIcon size={32} />
                        </TwitterShareButton>
                        <EmailShareButton url={window.location.href}>
                            <EmailIcon size={32} />
                        </EmailShareButton>
                    </div>
                </div>
                {/* {
                    MediaPostimet.map((mediap, key) => {
                        return (
                            <div key={key}><img src={`${path}${mediap.mediaEmri}${mediap.mediaEx}`} alt="" height="auto" width="600px" /></div>
                        );
                    })
                } */}
                {MediaPostimet.length === 0 ? ('') : (<ProductGallery images={MediaPostimet} />)}

            </div>
        </div>
    );
}

NewsDetailsPost.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
NewsDetailsPost.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsDetailsPost);
