// react
import React, { Fragment } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { FormattedMessage } from 'react-intl';

// application
// import { ArrowRoundedRight6x9Svg } from '../../svg';

function PageHeader(props) {
    let { header, breadcrumb } = props;
    const { shfaqbreadcrumb } = props;
    if (header) {
        header = (
            <div className="page-header__title">
                <h1>{header}</h1>
            </div>
        );
    }
    let layoutlink;
    // const homeBreadcrumb = (
    //     <Fragment>
    //         <li className="breadcrumb-item">
    //             <Link to="/"><FormattedMessage id="MobileMenu.Home" defaultMessage="Ballina" /></Link>
    //         </li>
    //         <li className="breadcrumb-item">
    //             /
    //         </li>
    //     </Fragment>
    // );
    if (breadcrumb.length > 0) {
        const lastIndex = breadcrumb.length - 1;
        breadcrumb = breadcrumb.map((item, index) => {
            let link;
            if (lastIndex === index) {
                link = <li key={index} className="breadcrumb-item active" aria-current="page">{item.pageName}</li>;
            } else {
                link = (
                    <Fragment>
                        <li key={index} className="breadcrumb-item">
                            <Link to={item.url}>{item.pageName}</Link>
                            {/* <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" /> */}
                        </li>
                        <li className="breadcrumb-item">
                            /
                        </li>
                    </Fragment>
                );
            }
            return link;
        });
        if (shfaqbreadcrumb !== false) {
            breadcrumb = (
                <div className="page-header__breadcrumb">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {/* {homeBreadcrumb} */}
                            {layoutlink}
                            {breadcrumb}
                        </ol>
                    </nav>
                </div>
            );
        }
    }

    return (
        <div className="page-header">
            <div className="page-header__container container">
                {shfaqbreadcrumb !== false ? breadcrumb : ''}
                {header}
            </div>
        </div>
    );
}

PageHeader.propTypes = {
    /** page header */
    header: PropTypes.node,
    /** array of breadcrumb links */
    breadcrumb: PropTypes.array,
};

PageHeader.defaultProps = {
    breadcrumb: [],
};

export default PageHeader;
