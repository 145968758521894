import React from 'react';
import Fade from 'react-reveal/Fade';
import Search from './Search';
import searchIcone from '../../img/Search-icone/search.png';
import useToggle from './useToggle';

export default function SearchScroll() {
    const [value, toggleValue] = useToggle(false);
    // const handleScroll = () => {
    //     const offset = window.scrollY;
    //     if (offset > 280) {
    //         toggleValue(true);
    //     } else {
    //         toggleValue(false);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    // });
    return (
        <div>
            {value && (
                <Fade big>
                    <div className="site-scroll__search">
                        <Search context="scrolled" />
                        <button
                            className="search-scroll-close"
                            type="button"
                            onClick={() => toggleValue((prev) => !prev)}
                        >
                            x
                        </button>
                    </div>

                </Fade>
            )}
            <button
                className="search-scroll-btn"
                type="button"
                onClick={() => toggleValue((prev) => !prev)}
            >
                <img src={searchIcone} alt="" />
            </button>
        </div>
    );
}
