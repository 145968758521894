// react
import React, { useState, useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
// import DatePicker from 'react-date-picker';
import { useParams } from 'react-router-dom';
// import { FormattedMessage } from 'react-intl';
// import moment from 'moment';

import NewsCard from './NewsCard';
import BlogSidebar from '../sidebar/BlogSidebar';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
// import PostLoader from '../../blocks/PostLoader';
import PageSlider from '../page/PageSlider';
import getMenuLocation from '../page/getMenuLocation';
import '@formatjs/intl-datetimeformat/polyfill-force';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/add-all-tz';
import '@formatjs/intl-datetimeformat/locale-data/sq';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/sr-Latn';

function NewsCategory(props) {
    const url = window.location.pathname.split('/').pop();
    const { layout, sidebarPosition } = props;
    // let { locale } = props;
    let { locale } = props;
    const params = useParams();
    const [menuLocationId, setmenuLocationId] = useState();
    const [post, setPost] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    // const [loader, setLoader] = useState(false);

    // const [dateFrom, setDateFrom] = useState();
    // const [newdateFrom, setNewDateFrom] = useState('');

    // const [dateTo, setDateTo] = useState();
    // const [newdateTo, setNewDateTo] = useState('');

    // const [search, setSearch] = useState({
    //     searchText: '',
    // });

    if (locale === 'sr') {
        locale = 'sr-Latn';
    }

    const handleChange = (skip = 0) => {
        // setLoader(true);
        // const searchfilter = search.searchText !== null ? `&searchText=${search.searchText}` : '';
        // const filterFrom = newdateFrom !== null ? `&DateFrom=${newdateFrom}` : '';
        // const filterTo = newdateTo !== null ? `&DateTo=${newdateTo}` : '';
        // getData(`News/GetNewsWithCategoryFilter?PageId=${params.id}&Gjuha=${locale}&skip=${skip}&take=10&TitulliLength=100&PermbajtjaLength=280${searchfilter}${filterFrom}${filterTo}`).then((response) => {
        //     setPost(response.data.lajmetLista);
        //     setTotalPage(response.data.totalPages);
        // });
        getData(`News/GetNewsWithCategoryFilter?PageId=${params.id}&Gjuha=${locale}&skip=${skip}&take=10&TitulliLength=100&PermbajtjaLength=280`).then((response) => {
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
            // setLoader(false);
        });
    }
    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setmenuLocationId(response.data[0].menuLocationGroupID);
        });
        handleChange();
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };

    // const handleChangetext = (e) => {
    //     e.preventDefault();
    //     setSearch({
    //         ...search,
    //         [e.target.name]: e.target.value,
    //     });
    // }

    // const handleDateFrom = (value) => {
    //     if (value === null) {
    //         setDateFrom(value);
    //         setNewDateFrom('');
    //     } else {
    //         const newValue = moment(value).format('YYYY-MM-DD');
    //         setDateFrom(value);
    //         setNewDateFrom(newValue);
    //     }
    // }

    // const handleDateTo = (value) => {
    //     if (value === null) {
    //         setDateTo(value);
    //         setNewDateTo('');
    //     } else {
    //         const newValue = moment(value).format('YYYY-MM-DD');
    //         setDateTo(value);
    //         setNewDateTo(newValue);
    //     }
    // }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     handlePageChange(1);
    // }

    let sidebarStart;
    let sidebarEnd;
    const template = getMenuLocation(menuLocationId);
    const sidebar = <BlogSidebar layout={template} position={sidebarPosition} />;

    if (sidebarPosition === 'start') {
        sidebarStart = <div className="col-12 col-lg-3 order-1 order-lg-0">{sidebar}</div>;
    } else if (sidebarPosition === 'end') {
        sidebarEnd = <div className="col-12 col-lg-3">{sidebar}</div>;
    }

    // let searchInput;
    // if (locale === 'sq') {
    //     searchInput = (
    //         <input
    //             type="text"
    //             id="searchText"
    //             name="searchText"
    //             placeholder="Kërko"
    //             onChange={handleChangetext}
    //             className="form-control form-docs"
    //         />
    //     )
    // } else if (locale === 'en') {
    //     searchInput = (
    //         <input
    //             type="text"
    //             id="searchText"
    //             name="searchText"
    //             placeholder="Search"
    //             onChange={handleChangetext}
    //             className="form-control form-docs"
    //         />
    //     )
    // } else if (locale === 'sr') {
    //     searchInput = (
    //         <input
    //             type="text"
    //             id="searchText"
    //             name="searchText"
    //             placeholder="Pretraga"
    //             onChange={handleChangetext}
    //             className="form-control form-docs"
    //         />
    //     )
    // }

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item news-list">
                <NewsCard post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <PageSlider />
            <div className="container box-shadow">
                <div className="row">
                    {sidebarStart}
                    <div className="col-12 col-lg-9 block-news-section">
                        <div className="block">
                            <div className="posts-view">
                                <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                    <div className="lajmet-list__body">

                                        {/* <div className="news-filter-grid">
                                            <div className="datepicker-grid">
                                                <div className="docs-grid datepicker dateFrom">
                                                    <h5><FormattedMessage id="from" defaultMessage="Prej" /></h5>
                                                    <DatePicker name="DateFrom" locale={locale} onChange={handleDateFrom} value={dateFrom} format="dd/MM/yyyy" />
                                                </div>
                                                <div className="docs-grid datepicker dateTo">
                                                    <h5><FormattedMessage id="to" defaultMessage="Deri" /></h5>
                                                    <DatePicker name="DateTo" locale={locale} onChange={handleDateTo} value={dateTo} format="dd/MM/yyyy" />
                                                </div>
                                            </div>
                                            <div className="docs-grid">
                                                {searchInput}
                                            </div>
                                            <div className="docs-grid">
                                                <button
                                                    id="submit"
                                                    type="submit"
                                                    className="btn btn-primary docs-search-btn"
                                                    onClick={handleSubmit}
                                                >
                                                    <FormattedMessage id="Search.name" defaultMessage="Kërko" />
                                                </button>
                                            </div>
                                        </div> */}
                                        {/* {
                                            loader === true ? <PostLoader /> : ''
                                        } */}
                                        {postsList}
                                    </div>
                                </div>
                                <div className="posts-view__pagination">
                                    <Pagination
                                        current={CurrentPage}
                                        siblings={2}
                                        total={totalPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {sidebarEnd}
                </div>
            </div>
        </React.Fragment>
    );
}

NewsCategory.propTypes = {
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

NewsCategory.defaultProps = {
    layout: 'list',
    sidebarPosition: 'end',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCategory);
