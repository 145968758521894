// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';
// application

function BoxSidebar(props) {
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [Sidebar, setSidebar] = useState([]);
    const params = useParams();
    const id = params.id === undefined ? 0 : params.id;
    useEffect(() => {
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=5&ParentPageID=${id}`).then((response) => {
            setSidebar(response.data);
        });
    }, [url]);

    const sidebarBox = Sidebar.map((item, key) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media[0]?.mediaEmri}${item.media[0]?.mediaEx}`;
        return (
            <Fragment key={key}>
                {
                    item.media.length > 0 && item.otherSource === true ? (
                        <a className="sidebar-box-img" href={item.url} target={item.targeti}>
                            {/* <img src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource} alt="" /> */}
                            <LazyLoadImage
                                alt=""
                                effect="blur"
                                placeholderSrc="/images/blur/solid.jpg"
                                src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource}
                            />
                        </a>
                    ) : (
                        item.media.length > 0 && item.otherSource === false ? (
                            <Link className="sidebar-box-img" to={item.url}>
                                {/* <img src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource} alt="" /> */}
                                <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    placeholderSrc="/images/blur/solid.jpg"
                                    src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource}
                                />
                            </Link>
                        ) : (
                            <a href={item.url} target={item.targeti} className="block-features__item box-link">
                                <div className="block-features__title ballinaBoxes-link">
                                    {item.pageName}
                                </div>
                            </a>
                        )
                    )
                }
            </Fragment>
        );
    });

    return (
        <Fragment>
            {
                Sidebar.length > 0 ? (
                    <div className="sidebar-box">
                        {sidebarBox}
                    </div>
                ) : ('')
            }
        </Fragment>
    );
}

BoxSidebar.propTypes = {
    /**
     * category array
     */
    /**
     * widget location (default: 'blog')
     * one of ['blog', 'shop']
     */
    locale: PropTypes.string,
};

BoxSidebar.defaultProps = {
    // layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BoxSidebar);
