// react
import { React, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FormattedMessage } from 'react-intl';
// third-party
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import { getData } from '../../../services/fetch-service';

function PersonelDetailsData(props) {
    const { layout, locale } = props;
    const history = useHistory();
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const [personelDetails, setPersonelDetails] = useState([]);
    useEffect(() => {
        getData(`Personel/GetPersonelDetails?PersoneliID=${params.postimiId}&Gjuha=${locale}`).then((response) => {
            setPersonelDetails(response.data[0]);
        }).catch(() => {
            history.push('/404');
        });
    }, [url]);
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${personelDetails.media?.mediaEmri}${personelDetails.media?.mediaEx}`;
    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                <div className="personel-details-data">
                    <div className="personel-card-box">
                        <div className="personel-image-card">
                            {/* <img src={personelDetails.media === null ? '/images/default.jpg' : image} alt="" /> */}
                            {personelDetails.media?.mediaEmri !== undefined && (
                                <LazyLoadImage
                                    alt=""
                                    width="100%"
                                    className="PageSlider"
                                    placeholderSrc="/images/blur/default.jpg"
                                    effect="blur"
                                    src={personelDetails.media === null ? '/images/default.jpg' : image}
                                />
                            )}
                        </div>
                        <div className="personel-card-content">
                            <div className="">
                                <div className="personel-card-info">
                                    <div className="personel-info-name">
                                        <p className="personel-info-type">
                                            <FormattedMessage id="Personel.card.name" defaultMessage="Example Text" />
                                        </p>
                                        <p className="personel-info-text">
                                            {personelDetails.personeliEmri}
                                            &nbsp;
                                            {personelDetails.personeliMbiemri}
                                        </p>
                                    </div>
                                    <div className="personel-info-position">
                                        <p className="personel-info-type">
                                            <FormattedMessage id="Personel.card.position" defaultMessage="Example Text" />
                                        </p>
                                        <p className="personel-info-text">
                                            {personelDetails.personeliPozita}
                                        </p>
                                    </div>
                                    {personelDetails.presoneliVendiLindjes === '' ? ('')
                                        : (
                                            <div className="personel-info-born-location">
                                                <p className="personel-info-type">
                                                    <FormattedMessage id="Personel.card.bornlocation" defaultMessage="Example Text" />
                                                </p>
                                                <p className="personel-info-text">
                                                    {personelDetails.presoneliVendiLindjes}
                                                </p>
                                            </div>
                                        )}
                                    {(personelDetails.personeliDataLindjes !== null && (
                                        <div className="personel-info-birthday">
                                            <p className="personel-info-type">
                                                <FormattedMessage id="Personel.card.birthday" defaultMessage="Example Text" />
                                            </p>
                                            <p className="personel-info-text">
                                                {Moment(personelDetails.personeliDataLindjes).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                    ))}
                                    {personelDetails.personeliEmail === '' ? ('')
                                        : (
                                            <div className="personel-info-email">
                                                <p className="personel-info-type">
                                                    <FormattedMessage id="Personel.card.email" defaultMessage="Example Text" />
                                                </p>
                                                <p className="personel-info-text">
                                                    {personelDetails.personeliEmail}
                                                </p>
                                            </div>
                                        )}
                                    {personelDetails.personeliNrTelefonit === '' ? ('')
                                        : (
                                            <div className="personel-info-phone">
                                                <p className="personel-info-type">
                                                    <FormattedMessage id="Personel.card.phone" defaultMessage="Example Text" />
                                                </p>
                                                <p className="personel-info-text">
                                                    {personelDetails.personeliNrTelefonit}
                                                </p>
                                            </div>
                                        )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="personel-texteditor">
                        <p
                            dangerouslySetInnerHTML={{ __html: personelDetails.personeliInformataShtes }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

PersonelDetailsData.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    // locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
PersonelDetailsData.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PersonelDetailsData);
