// react
import React, { useState, useEffect } from 'react';

// third-party
// import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';

import DropdownLanguage from './DropdownLanguage';
import Search from './Search';

function Topbar(props) {
    const { locale } = props;
    const [socialLinks, setSocialLinks] = useState([]);

    useEffect(() => {
        getData(`Home/GetSocialNetworks?Gjuha=${locale}&Layout=default`).then((response) => {
            setSocialLinks(response.data);
        })
    }, []);

    let logo;

    if (locale === 'sq') {
        logo = (
            <div className="site-header__logo-bottom">
                <Link to="/"><img src="/images/logot/mm-shq.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'en') {
        logo = (
            <div className="site-header__logo-bottom">
                <Link to="/"><img src="/images/logot/mm-en.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'sr') {
        logo = (
            <div className="site-header__logo-bottom">
                <Link to="/"><img src="/images/logot/mm-sr.png" alt="" /></Link>
            </div>
        );
    }

    const socials = socialLinks.map((item, key) => (
        <li key={key} className="social-links__item">
            {item.html !== '' && item.html !== null ? (
                <a
                    className="social-links__link topbar-socials"
                    href={item.linku}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className={item.html} />
                </a>
            ) : (
                <a
                    className={`social-links__link topbar-socials social-links__link--type--${item.emertimi}`}
                    href={item.linku}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={`${item.imgPath}`} alt="" width="34px" height="34px" />
                </a>
            )}
        </li>
    ));

    return (
        <div className="topbar">
            <div className="container">
                <div className="row topbar_row">
                    <div className="left-topbar">
                        {logo}
                    </div>
                    <div className="right-topbar">
                        <div className="site-header__search">
                            <Search context="header" />
                        </div>
                        <div className="topbar-box-content">
                            <div className="topbar__item">
                                <DropdownLanguage />
                            </div>
                        </div>
                        <ul className="social-links__list">
                            {socials}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Topbar);
