// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';

// pages
import Layout from './Layout';
import HomePageOne from './home/HomePageOne';

// import Page from './cms_components/page/Page';
// import PageDocs from './cms_components/page/PageDocs';
import PageDocsFilter from './cms_components/page/PageDocsFilter';
import News from './cms_components/news/News';
import NewsArchive from './cms_components/news/NewsArchive';
import NewsDetails from './cms_components/news/NewsDetails';
import Faq from './cms_components/Faq';
import Personel from './cms_components/personel/Personel';
import PersonelDetails from './cms_components/personel/PersonelDetails';
import PhotoGalery from './cms_components/fotogalery/FotoGalery';
import PhotoGaleryDetails from './cms_components/fotogalery/FotoGaleryDetails';
import VideoGalery from './cms_components/videoGalery/VideoGalery';
import VideoGaleryDetails from './cms_components/videoGalery/VideoGaleryDetails';
// import Services from './cms_components/Services';
// import Projects from './cms_components/Projects';
import Contact from './cms_components/Contact';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageError from './site/SitePageError';
import Search from './cms_components/search/Search';
import NewsCategory from './cms_components/news/NewsCategory';
import events from './cms_components/eventet/events';

class Root extends Component {
    componentDidMount() {
        // preloader
        setTimeout(() => {
            const preloader = document.querySelector('.site-preloader');

            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity') {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }, 500);

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get('dir');
        if (direction !== null) {
            changeLocale(direction === 'rtl' ? 'ar' : 'en');
        }
    }

    shouldUpdateScroll = (prevRouterProps, { location }) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );

    render() {
        const { locale } = this.props;
        const { messages, direction } = languages[locale];

        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={HomePageOne} />
                                    )}
                                />
                                <Route
                                    path="/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={events} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/News"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={News} />
                                    )}
                                />
                                <Route
                                    path="/News/:slug"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={News} />
                                    )}
                                />
                                <Route
                                    path="/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={NewsDetails} />
                                    )}
                                />
                                <Route
                                    path="/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PhotoGalery} />
                                    )}
                                />
                                <Route
                                    path="/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PhotoGaleryDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={VideoGalery} />
                                    )}
                                />
                                <Route
                                    path="/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={VideoGaleryDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Contact/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Contact} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/FAQ"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Search/:slug"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Search} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/404"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={SitePageNotFound} showslider="false" />
                                    )}
                                />
                                <Route path="/error">
                                    <SitePageError />
                                </Route>
                                <Redirect to="/" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
