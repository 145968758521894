// react
import React, { useState, useEffect, Fragment } from 'react';

// third-party
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// application
import { getData } from '../../services/fetch-service';

function HomeBoxes(props) {
    const { locale } = props;
    const [ballinaBox, setBallinaBox] = useState([]);

    useEffect(() => {
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=4`).then((response) => {
            setBallinaBox(response.data);
        });
    }, []);

    const boxes = ballinaBox.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media[0]?.mediaEmri}${item.media[0]?.mediaEx}`;
        return (
            <Fragment key={index}>
                {
                    item.media.length > 0 && item.otherSource === true ? (
                        <li>
                            <a className="slider-box-link" href={item.url} target={item.targeti}>
                                <img src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource} alt="" />
                                <strong className="box-mm">
                                    {item.pageName}
                                </strong>
                            </a>
                        </li>
                    ) : (
                        item.media.length > 0 && item.otherSource === false ? (
                            <li>
                                <Link className="slider-box-link" to={item.url}>
                                    <img src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource} alt="" />
                                    <strong className="box-mm">
                                        {item.pageName}
                                    </strong>
                                </Link>
                            </li>
                        ) : (
                            <a href={item.url} target={item.targeti} className="block-features__item box-link">
                                <div className="block-features__title ballinaBoxes-link">
                                    {item.pageName}
                                </div>
                            </a>
                        )
                    )
                }
            </Fragment>
        );
    });

    return (
        <div className="block-slideshow__slide-content">
            <ul className="slider-boxes">
                {boxes}
            </ul>
        </div>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(HomeBoxes);
