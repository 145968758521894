import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SkeletonMinistri() {
    return (
        <div className="skeleton-grid">
            <div className="Mayor-msg">

                <div className="Mayor-thumb">
                    <Skeleton variant="rectangular" />
                </div>

                <div className="Mayor-text">
                    <span className="mayor-title">
                        <Skeleton animation="wave" />
                    </span>
                    <span className="mayor-name">
                        <Skeleton animation="wave" />
                    </span>
                    <ul className="team-social">
                        <li><Skeleton animation="wave" /></li>
                        <li><Skeleton animation="wave" /></li>
                        <li><Skeleton animation="wave" /></li>
                        <li><Skeleton animation="wave" /></li>
                    </ul>
                </div>

            </div>
        </div>
    );
}
