import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import NavLinks from './NavLinks2';
// import Search from './Search';
import SearchScroll from './SearchScroll';

function NavPanel() {
    const [scrolled, setScrolled] = React.useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 180) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    const navbarClasses = ['logo-nav-row'];
    const logoClasses = ['site-header__logo'];
    const searchClasses = ['site-header__search-top'];

    if (scrolled) {
        navbarClasses.push('scrolled');
        logoClasses.push('scroll');
        searchClasses.push('scroll');
    }

    const links = (
        <Fragment>
            <div className={navbarClasses.join('')}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={logoClasses.join('')}>
                                <Link to="/"><img src="/images/logot/MFPT_SCROLL.png" alt="" /></Link>
                            </div>
                            <div className="navbar">
                                <NavLinks />
                            </div>
                            <div className={searchClasses.join('')}>
                                <SearchScroll />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            {links}
        </Fragment>

    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
