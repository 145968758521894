// react
import { React, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// third-party
// import Moment from 'moment';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import PostLoader from '../../blocks/PostLoader';

function PersonelCard(props) {
    const { layout } = props;
    const params = useParams();
    const cardClasses = classNames(
        'personel-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-nl-4', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'grid-nl-4', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [Page, setPage] = useState([]);
    const [personeli, setPersoneli] = useState([]);
    const [TotalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [loader, setLoader] = useState(false);

    const handleChange = (skip = 0) => {
        setLoader(true);
        getData(`Personel/GetPersonels?PageID=${params.id}&Gjuha=${locale}&skip=${skip}&take=9`).then((response) => {
            setPersoneli(response.data.result);
            setTotalPage(response.data.totalPages);
            setLoader(false);
        });
        setLoader(false);
    }

    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setPage(response.data);
        });
        handleChange();
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 9) - 9;
        handleChange(skip);
    };

    const personeliList = personeli.map((personeli, key) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${personeli.media?.mediaEmri}${personeli.media?.mediaEx}`;
        return (
            <Link to={personeli.url} key={key} className={cardClasses}>
                <div className="avatar-img">
                    {personeli.media?.mediaEmri !== undefined && (
                        <LazyLoadImage
                            alt=""
                            width="100%"
                            className="PageSlider"
                            placeholderSrc="/images/blur/default.jpg"
                            effect="blur"
                            src={personeli.media === null ? '/images/default.jpg' : image}
                        />
                    )}

                </div>
                <div className="personel-card-content-grid">
                    <div className="text-section-box">
                        <div className="personel-box-info">
                            {(personeli.personeliEmri.length === 0 || personeli.personeliMbiemri.length === 0) ? (
                                ('')
                            ) : (
                                <div className="personel-info-name">
                                    <p className="personel-info-text">
                                        {personeli.personeliEmri}
                                        &nbsp;
                                        {personeli.personeliMbiemri}
                                    </p>
                                </div>
                            )}
                            {(personeli.personeliPozita.length === 0) ? (
                                ('')
                            ) : (
                                <div className="personel-info-position">
                                    <p className="personel-info-text">
                                        {personeli.personeliPozita}
                                    </p>
                                </div>
                            )}
                            {(personeli.personeliEmail.length === 0) ? (
                                ('')
                            ) : (
                                <div className="personel-info-email">
                                    <p className="personel-info-text">
                                        {personeli.personeliEmail}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        );
    });

    return (
        <div>
            <div className="block personel-block">
                <div className="posts-view">
                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                        <div className="personel-grid">
                            {
                                loader === true ? <PostLoader /> : ''
                            }
                            {personeliList}
                        </div>
                    </div>
                    <div className="posts-view__pagination personel-pagination">
                        {
                            Page.length > 0 ? (
                                <div className="posts-view__pagination">
                                    <Pagination
                                        current={CurrentPage}
                                        siblings={2}
                                        total={TotalPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            ) : ('')
                        }
                    </div>
                </div>
            </div>

        </div>

    );
}

PersonelCard.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['grid', 'grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};
PersonelCard.defaultProps = {
    layout: 'grid',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PersonelCard);
