// react
import { React, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// third-party
// import Moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import Loader from '../../blocks/PostLoader';

function SearchContent(props) {
    const { layout, locale } = props;
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const [Page, setPage] = useState([]);
    const [TotalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [loader, setLoader] = useState(false);
    const handleChange = (skip = 0) => {
        setLoader(true);
        getData(`Home/Search?parameter=${params.slug}&Gjuha=${locale}&skip=${skip}&take=10`).then((response) => {
            setPage(response.data.searchAll);
            setTotalPage(response.data.totalPages);
            setLoader(false);
        });
    }

    useEffect(() => {
        handleChange();
        setCurrentPage(1);
    }, [url]);
    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };
    const path = process.env.REACT_APP_FILESPATH;
    const List = Page.map((page, key) => {
        const link = `${path}${page.url}`;
        return (
            <div key={key} className="col-md-12">
                <div className="file_manager">
                    <div className="card-docs">
                        <div className="item-docs">
                            <div className="file-name">
                                {page.categoryId === 2
                                    ? (
                                        <a href={link} target="_blank" rel="noreferrer">{page.name}</a>)
                                    : page.otherSource === true
                                        ? (
                                            <a href={page.url} target="_blank" rel="noreferrer">{page.name}</a>
                                        ) : (
                                            <Link
                                                to={page.url}
                                                rel="noreferrer"
                                            >
                                                {page.name}
                                            </Link>
                                        )}
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        );
    });

    return (
        <div>
            <div className={`block post post--layout--${layout}`}>
                <div className={`post__header post-header post-header--layout--${layout}`} />
            </div>
            <div className="">
                <div className="col-12 col-lg-12">
                    <div className="block">
                        <div className="posts-view">

                            <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                <div className="posts-list__body search__body">
                                    {
                                        loader === true ? <Loader /> : Page.length > 0 ? (List)
                                            : <FormattedMessage id="search" defaultMessage="search" />
                                    }
                                </div>
                            </div>
                            <div className="posts-view__pagination docs-pagination">
                                {
                                    Page.length > 0 ? (
                                        <div className="posts-view__pagination">
                                            <Pagination
                                                current={CurrentPage}
                                                siblings={2}
                                                total={TotalPage}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    ) : ('')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

SearchContent.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
SearchContent.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(SearchContent);
