// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';
// application
import Collapse from '../../shared/Collapse';
import { ArrowRoundedDown9x6Svg } from '../../../svg';

function SidebarMeny(props) {
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [Sidebar, setSidebar] = useState([]);
    const params = useParams();
    const id = params.id === undefined ? 0 : params.id;
    useEffect(() => {
        getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=2&ParentPageID=${id}`).then((response) => {
            setSidebar(response.data);
        });
    }, [url]);

    const para = parseInt(params.id, 10);
    const sidebarList = Sidebar.map((sidebar, key) => {
        const renderCategory = (
            {
                toggle, setItemRef, setContentRef,
            },
        ) => {
            let expander;
            let children;
            if (sidebar.submenu && sidebar.hasChild === true) {
                expander = <ArrowRoundedDown9x6Svg width="20px" height="20px" className="widget-categories__arrow" onClick={toggle} />;
                children = (
                    <div className="widget-categories__subs" ref={setContentRef}>
                        <ul>
                            {sidebar.submenu.map((children, index) => (
                                <li key={index} style={{ color: children.pageId === para ? '#D0A650' : '#fff' }}>
                                    {
                                        sidebar.otherSource === false ? (
                                            <Link to={children.url}>{children.pageName}</Link>
                                        ) : (
                                            <a href={children.url} target={children.targeti}>{children.pageName}</a>
                                        )
                                    }
                                    {children.submenu.map((sub, index1) => (
                                        <div key={index1} className="widget-categories__subs" ref={setContentRef}>
                                            <ul>
                                                <li key={index1} style={{ color: sub.pageId === para ? '#D0A650' : '#000' }}>
                                                    {
                                                        sidebar.otherSource === false ? (
                                                            <Link to={sub.url}>
                                                                {sub.pageName}
                                                            </Link>
                                                        ) : (
                                                            <a href={sub.url} target={sub.targeti}>
                                                                {sub.pageName}
                                                            </a>
                                                        )
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            }

            return (
                <li key={key} ref={setItemRef}>
                    <div className="panel-heading">
                        <strong>
                            {
                                sidebar.otherSource === false ? (
                                    <Link to={sidebar.url} onClick={toggle}>
                                        {sidebar.pageName}
                                    </Link>
                                ) : (
                                    <a href={sidebar.url} onClick={toggle} target={sidebar.targeti}>
                                        {sidebar.pageName}
                                    </a>
                                )
                            }

                            <div>
                                {expander}
                            </div>
                        </strong>
                    </div>
                    {children}
                </li>
            );
        };

        return <Collapse key={key} toggleClass="widget-categories__item--open" render={renderCategory} />;
    });

    return (
        <Fragment>
            {
                Sidebar.length > 0 ? (
                    <div className="side3d-mm">
                        <ul>
                            {sidebarList}
                        </ul>
                    </div>
                ) : ('')
            }
        </Fragment>
    );
}

SidebarMeny.propTypes = {
    /**
     * category array
     */
    /**
     * widget location (default: 'blog')
     * one of ['blog', 'shop']
     */
    locale: PropTypes.string,
};

SidebarMeny.defaultProps = {
    // layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(SidebarMeny);
