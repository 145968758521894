import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SkeletonFirstNews() {
    return (
        <div className="skeleton-grid">
            <div className="event-post-full">
                <div className="thumb">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="event-post-content">
                    <div className="event-post-txt">
                        <div className="post-card__name">
                            <Skeleton animation="wave" />
                        </div>
                        <ul className="event-meta">
                            <li className="firstnews-date-skeleton">
                                <Skeleton animation="wave" />
                            </li>
                        </ul>
                        <div className="firstnews-text-skeleton">
                            <p>
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
