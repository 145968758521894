import { React, useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// third-party
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';

function VegezatSidebar(props) {
    const { locale } = props;
    const [Vegzat, setVegzatPage] = useState([]);

    useEffect(() => {
        getData(`SideBar/GetLinks?LinkTypeID=1&PageID=1&Gjuha=${locale}`).then((response) => {
            setVegzatPage(response.data);
        });
    }, []);

    const vegzatList = Vegzat.map((vegzat, index) => {
        return (
            <li key={index}>
                <div className="panel-heading">
                    <strong>
                        <a
                            href={vegzat.linku}
                            target={vegzat.linkuHape}
                        >
                            {vegzat.linkuPershkrimi}
                        </a>
                    </strong>

                </div>
            </li>
        );
    });

    return (
        vegzatList.length > 0 ? (
            <div className="latest-updates">
                <ul>
                    {vegzatList}
                </ul>
            </div>
        ) : ('')

    );
}

VegezatSidebar.propTypes = {
    locale: PropTypes.string,
    // layout: PropTypes.oneOf(['home', 'footer']),
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(VegezatSidebar);
