export default function getMenuLocation(menuLocationId) {
    let template = '';

    if (menuLocationId === 1 || menuLocationId === 2) {
        template = 'default';
    } else if (menuLocationId === 5) {
        template = 'default';
    } else {
        template = '';
    }
    return template;
}
