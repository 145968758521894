import axios from 'axios';

const CryptoJS = require('crypto-js');

const API_URL = process.env.REACT_APP_BASEURL;
let cipher = '';
const encryptKey = async () => {
    await axios.get(`${API_URL}Home/GetDate`).then((response) => {
        const apiKey = response.data;
        cipher = CryptoJS.AES.encrypt(apiKey.toString(), CryptoJS.enc.Utf8.parse(`${process.env.REACT_APP_API_SECRET_KEY}`), {
            keySize: 128 / 8,
            iv: CryptoJS.enc.Utf8.parse(`${process.env.REACT_APP_API_SECRET_KEY}`),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();
    });
    axios.defaults.headers.common = { key: `${cipher}` }
}
const getData = async (action) => {
    await encryptKey();
    const result = await axios.get(API_URL + action);
    return result;
};
const postData = async (action, data) => {
    await encryptKey();
    // return axios.post(API_URL + action, data);
    const result = await axios.post(API_URL + action, data);
    return result;
};
const putData = async (action, data) => {
    await encryptKey();
    // return axios.put(API_URL + action, data);
    const result = await axios.put(API_URL + action, data);
    return result;
};
const deleteData = async (action) => {
    await encryptKey();
    // return axios.delete(API_URL + action);
    const result = await axios.delete(API_URL + action);
    return result;
};

export {
    getData,
    postData,
    putData,
    deleteData,
};
