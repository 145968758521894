// react
import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Moment from 'moment';
import { connect } from 'react-redux';

function NewsCard(props) {
    const { news, locale } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${news.media?.mediaEmri}${news.media?.mediaEx}`;
    const video = `<video controls><source src="${image}"></video>`

    const cardClasses = ['event-post-full'];

    if (news.media.otherSource !== null) {
        if (news.media.otherSource.includes('iframe')) {
            cardClasses.push(' responsive-video');
        }
    }

    return (
        <div className={cardClasses.join('')}>
            <div className="thumb">
                <Link to={news.url}>
                    <i className="fas fa-link" />
                </Link>

                {news.media === null ? (
                    <img className="img-solid" src="/images/blur/news-solid.jpg" alt="" />
                )
                    : (
                        <Fragment>
                            {
                                news.media?.mediaExKategoriaId === 1 ? (
                                    <LazyLoadImage
                                        alt=""
                                        effect="blur"
                                        placeholderSrc="/images/blur/solid.jpg"
                                        src={news.media?.otherSource === null ? image : news.media?.otherSource}
                                    />
                                ) : (<div className="video-news embed-responsive embed-responsive-16by9" dangerouslySetInnerHTML={{ __html: news.media?.otherSource === null ? video : news.media?.otherSource }} />)
                            }
                        </Fragment>

                    )}
            </div>
            <div className="event-post-content">
                <div className="event-post-txt">
                    <div className="post-card__name">
                        <Link to={news.url}>{news.postimiTitulli}</Link>
                    </div>
                    <ul className="event-meta">
                        <li>
                            <i className="far fa-calendar-alt" />
                            &nbsp;
                            {Moment(news.postimiDataFillimit).locale(locale).format('LL')}
                        </li>
                    </ul>
                    <div className="post-card__content">
                        <p>
                            {news.postimiPershkrimi}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCard);
