export default {
    name_shq: 'Ministria e Mbrojtjes',
    name_en: 'Ministry of Defense',
    name_sr: 'Ministarstvo odbrane',
    fullName: 'Ministria e Mbrojtjes',
    url: '',
    author: {
        name: '',
        profile_url: '',
    },
    contacts: {
        address: '',
        email: '',
        phone: '',
    },
};
