// react
import React, { Fragment } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

import SidebarMeny from './SidebarMeny';
import Kalendari from './Kalendari';
import VegezatSidebar from './VegezatSidebar';
import MinistriSidebar from './MinistriSidebar';
import BoxSidebar from './BoxSidebar';

export default function Sidebar(props) {
    const { position } = props;

    const content = (
        <Fragment>
            <MinistriSidebar layout="default" />
            <div className="block-sidebar__item">
                <Kalendari />
            </div>
            <SidebarMeny layout="default" />
            <VegezatSidebar layout="home" />
            <BoxSidebar />
        </Fragment>
    );

    return (
        <div className={`block block-sidebar block-sidebar--position--${position}`}>
            <Fragment>
                {content}
            </Fragment>
        </div>
    );
}

Sidebar.propTypes = {
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    position: PropTypes.oneOf(['start', 'end']),
};

Sidebar.defaultProps = {
    position: 'start',
};
