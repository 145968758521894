// react
import React, { useState, useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// application
import Pagination from '../../shared/Pagination';
import VideoGaleryCard from './VideoGaleryCard';
import BlogSidebar from '../sidebar/BlogSidebar';
import { getData } from '../../../services/fetch-service';
import PageSlider from '../page/PageSlider';
import getMenuLocation from '../page/getMenuLocation';

function VideoGalery(props) {
    const url = window.location.pathname.split('/').pop();
    const { layout, sidebarPosition } = props;
    const { locale } = props;
    const params = useParams();
    const [post, setPost] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [menuLocationId, setmenuLocationId] = useState();

    const handleChange = (skip = 0) => {
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=2&PageID=${params.id}&Layout='default'&Gjuha=${locale}&skip=${skip}&take=10`).then((response) => {
            setPost(response.data.galeriaLista);
            setTotalPage(response.data.totalPages);
        });
    }
    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setmenuLocationId(response.data[0].menuLocationGroupID);
        });
        handleChange();
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        handleChange(skip);
    };

    let sidebarStart;
    let sidebarEnd;
    const template = getMenuLocation(menuLocationId);
    const sidebar = <BlogSidebar layout={template} position={sidebarPosition} />;

    if (sidebarPosition === 'start') {
        sidebarStart = <div className="col-12 col-lg-3 order-1 order-lg-0">{sidebar}</div>;
    } else if (sidebarPosition === 'end') {
        sidebarEnd = <div className="col-12 col-lg-3">{sidebar}</div>;
    }

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item galery-item">
                <VideoGaleryCard post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <PageSlider />
            <div className="container box-shadow">
                <div className="row">
                    {sidebarStart}
                    <div className="col-12 col-lg-9">
                        <div className="block">
                            <div className="posts-view">
                                <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                    <div className="posts-list__body galery-body">
                                        {postsList}
                                    </div>
                                </div>
                                <div className="posts-view__pagination galery-pagination">
                                    <Pagination
                                        current={CurrentPage}
                                        siblings={2}
                                        total={totalPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {sidebarEnd}
                </div>
            </div>
        </React.Fragment>
    );
}

VideoGalery.propTypes = {

    layout: PropTypes.oneOf(['classic', 'grid', 'list']),

    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

VideoGalery.defaultProps = {
    layout: 'grid',
    sidebarPosition: 'end',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(VideoGalery);
