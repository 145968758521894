import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import moment from 'moment';
import { getData } from '../../../services/fetch-service';
import '@formatjs/intl-datetimeformat/polyfill-force';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/add-all-tz';
import '@formatjs/intl-datetimeformat/locale-data/sq';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/sr-Latn';

function Kalendari(props) {
    let { locale } = props;
    const history = useHistory();
    const [value, setValue] = useState();
    const [monthvalue, setMonthValue] = useState();
    const [newsDate, setNewsDate] = useState([]);
    if (locale === 'sr') {
        locale = 'sr-Latn';
    }
    useEffect(() => {
        if (value !== undefined) {
            const newValue = moment(value).format('YYYY-MM-DD');
            getData(`News/GetCheckNewsForThisDate?Gjuha=${locale}&date=${newValue}`).then((response) => {
                if (response.data.countNews > 0) {
                    history.push(`/News/${newValue}`);
                }
            });
        }
    }, [value]);

    const markParticualDate = (value) => {
        getData(`News/MarkParticularDates?Gjuha=${locale}&date=${value}`).then((response) => {
            setNewsDate(response.data.countNews.result);
        });
    }

    useEffect(() => {
        const newMonthValue = moment(monthvalue).format('YYYY-MM-DD');
        markParticualDate(newMonthValue);
    }, [monthvalue]);

    const onChange = (value) => {
        setValue(value);
        const newValue = moment(value).format('YYYY-MM-DD');
        markParticualDate(newValue);
    }

    const onClickMonth = (value) => {
        setMonthValue(value);
    }

    return (
        <div>
            <Calendar
                onChange={onChange}
                onClickMonth={onClickMonth}
                value={value}
                locale={locale}
                tileClassName={({ date }) => {
                    if (newsDate.find((m) => moment(date).format('DD/MM/YYYY') === m)) {
                        return 'highlight';
                    }
                    return null;
                }}
                // activeStartDate={today}
                onActiveStartDateChange={({ activeStartDate, action }) => {
                    if (action === 'next' || action === 'next2') {
                        setMonthValue(activeStartDate)
                    }
                    if (action === 'prev' || action === 'prev2') {
                        setMonthValue(activeStartDate)
                    }
                }}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Kalendari);
