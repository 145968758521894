// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import PostCard from '../shared/PostCard';
import { getData } from '../../services/fetch-service';
import NewsCard from '../shared/NewsCard';
import SkeletonSecondNews from '../cms_components/Skeletons/Skeleton-ballin-second-news';
import SkeletonFirstNews from '../cms_components/Skeletons/Skeleton-ballin-first-news';

class BlockPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: [],
            firstNews: [],
            FirstLoader: (true),
            SecondLoader: (true),
        };
    }

    componentDidMount() {
        const { locale } = this.props;
        getData(`News/GetNews?Gjuha=${locale}&PostimiKategoriaID=1&skip=1&take=3&TitulliLength=75&PermbajtjaLength=155`).then((response) => {
            this.setState({
                post: response.data.lajmetLista,
                SecondLoader: (false),
            });
        });
        getData(`News/GetNews?Gjuha=${locale}&PostimiKategoriaID=1&skip=0&take=1&TitulliLength=115&PermbajtjaLength=175`).then((response) => {
            this.setState({
                firstNews: response.data.lajmetLista,
                FirstLoader: (false),
            });
        });
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { layout } = this.props;
        const {
            post,
            firstNews,
            SecondLoader,
            FirstLoader,
        } = this.state;
        const postsList = post.map((post) => <PostCard key={post.postimiId} post={post} />);
        const firstNewsList = firstNews.map((news) => <NewsCard key={news.postimiId} news={news} />);

        return (
            <div className={`block block-fsk-news block-posts block-posts--layout--${layout}`} data-layout={layout}>
                <div className="news-title">
                    <Link className="event-btn" to="/NewsCategory/33"><h3><FormattedMessage id="news-home-title" defaultMessage="Lajmet" /></h3></Link>
                </div>
                {
                    FirstLoader === true
                        ? (
                            <SkeletonFirstNews />
                        )
                        : (
                            <Fade big>
                                <div className="first-news-section">
                                    {firstNewsList}
                                </div>
                            </Fade>
                        )
                }
                {
                    SecondLoader === true
                        ? (
                            <SkeletonSecondNews />
                        )
                        : (
                            <Fade big>
                                <div className="lajmet-grid">
                                    {postsList}
                                </div>
                            </Fade>
                        )
                }

            </div>
        );
    }
}

BlockPosts.propTypes = {

    layout: PropTypes.oneOf(['list-sm', 'grid-nl', 'grid-nl-4']),
    locale: PropTypes.string,
};

BlockPosts.defaultProps = {
    layout: 'list-sm',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockPosts);
