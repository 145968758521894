// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
// third-party
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getData } from '../../services/fetch-service';
// application
import AppLink from '../shared/AppLink';
import Menu from './Menu';

function NavLinks2(props) {
    const { locale } = props;
    const history = useHistory();
    const [allLinks, setAllLinks] = useState([]);

    useEffect(() => {
        getData(`Home/GetMenus?LocationMenuID=1&Gjuha=${locale}`).then((response) => {
            setAllLinks(response.data);
        }).catch(() => {
            history.push('/error');
        });
    }, []);

    const [isActive, setActive] = useState('true');

    const handleMouseOver = () => {
        setActive(true);
    };

    const ToggleClass = () => {
        setActive(!isActive);
    };

    const linksList = allLinks.map((item, index) => {
        let submenu;

        const classesMenu = classNames(item.isMegaMenu === true ? 'nav-links__menu megamenu_menu' : 'nav-links__menu', {
            'nav-links__item--with-submenu': item.submenu,
        });

        if (item.submenu && item.hasChild === true) {
            submenu = (

                <div className={classesMenu}>
                    <div className={isActive ? 'active' : 'inactive'}>
                        <Menu items={item.submenu} onClick={ToggleClass} />
                    </div>
                </div>
            );
        }

        const classes = classNames(item.isMegaMenu === true ? 'nav-links__item' : 'nav-links__item default-nav', {
            'nav-links__item--with-submenu': item.submenu,
        });

        return (
            <li className={classes} key={index}>
                {
                    item.otherSource === true ? (
                        <a href={item.url} target={item.targeti}>
                            {item.pageName}
                        </a>
                    ) : (
                        <Fragment>
                            {
                                item.isClicked === false ? (
                                    <span className="nav-title" {...item.props} onFocus={handleMouseOver} onMouseOver={handleMouseOver}>
                                        {item.pageName}
                                    </span>
                                ) : (
                                    <AppLink className="nav-title" to={item.url} target={item.targeti} {...item.props} onMouseOver={handleMouseOver}>
                                        {item.pageName}
                                    </AppLink>
                                )
                            }
                        </Fragment>
                    )
                }
                {submenu}
            </li>
        );
    });

    return (
        <ul className="nav navbar-nav">
            {linksList}
        </ul>
    );
}

NavLinks2.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks2);
