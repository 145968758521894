// react
import React, { Fragment } from 'react';
import Moment from 'moment';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

function NewsCard(props) {
    const { post, layout, locale } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${post.media?.mediaEmri}${post.media?.mediaEx}`;
    const video = `<video controls><source src="${image}"></video>`
    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-nl-4', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'grid-nl-4', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    return (
        <div className={cardClasses}>
            <div className="post-card__image">
                <Link to={post.url}>
                    {/* <img src={post.media?.otherSource === null ? image : post.media?.otherSource} alt="" /> */}
                    {post.media === null ? (
                        <img className="img-solid" src="/images/blur/news-solid.jpg" alt="" />
                    ) : (
                        <Fragment>
                            {
                                post.media?.mediaExKategoriaId === 1 ? (
                                    <LazyLoadImage
                                        alt=""
                                        effect="blur"
                                        placeholderSrc="/images/blur/solid.jpg"
                                        src={post.media?.otherSource === null ? image : post.media?.otherSource}
                                    />
                                ) : (<div className="video-news embed-responsive embed-responsive-16by9" dangerouslySetInnerHTML={{ __html: post.media?.otherSource === null ? video : post.media?.otherSource }} />)
                            }
                        </Fragment>
                    )}
                </Link>
            </div>
            <div className="post-card__info">
                <div className="post-card__name">
                    <Link to={post.url}>{post.postimiTitulli}</Link>
                </div>
                <ul className="event-meta">
                    <li>
                        <i className="far fa-calendar-alt" />
                        &nbsp;
                        {Moment(post.postimiDataFillimit).locale(locale).format('LL')}
                    </li>
                </ul>
                <div className="post-card__content">
                    <p>
                        {post.postimiPershkrimi}
                    </p>
                </div>
            </div>
        </div>
    );
}

NewsCard.propTypes = {
    /**
     * post data object
     */
    // post: PropTypes.array,
    /**
     * post card layout
     * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
     */
    layout: PropTypes.oneOf(['grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCard);
