// react
import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

function SitePageNotFound() {
    const history = useHistory();

    const clearCacheData = () => {
        if ('caches' in window) {
            window.caches.keys().then((cacheNames) => {
                Promise.all(
                    cacheNames.map((cacheName) => window.caches.delete(cacheName)),
                )
            });
        }

        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i += 1) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }

        localStorage.clear();
        history.push('/');
    }

    return (
        <div className="error-fp">
            <div className="block error-box">
                <div className="container">
                    <div className="not-found">
                        <div className="not-found__404">
                            <h1 className="not-found__title error_title">
                                <FormattedMessage id="Page.Error.Title" defaultMessage="Faqja për momentin nuk është në dispozicion ju lutem provoni më vonë" />
                            </h1>
                        </div>

                        <div className="not-found__content">
                            <button
                                className="btn btn-primary btn-about"
                                // to="/"
                                type="button"
                                onClick={clearCacheData}
                            >
                                <FormattedMessage id="Page.Error.btn" defaultMessage="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
