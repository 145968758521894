// react
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';

// application
import { connect } from 'react-redux';
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
// import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';
import FooterVegezat from './FooterVegezat';

// data stubs
// import theme from '../../data/theme';
// import SocialLinks from '../shared/SocialLinks';

function Footer(props) {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };
    const { locale } = props;

    let logo;

    if (locale === 'sq') {
        logo = (
            <img className="topbar-logo" src="/images/logot/mm-shq.png" alt="" />
        );
    } else if (locale === 'en') {
        logo = (
            <img className="topbar-logo" src="/images/logot/mm-en.png" alt="" />
        );
    } else if (locale === 'sr') {
        logo = (
            <img className="topbar-logo" src="/images/logot/mm-sr.png" alt="" />
        );
    }

    return (
        <Fragment>
            <div className="home3 main-footer wf100">
                <div className="container">
                    <div className="row hijezimi">
                        <div className="col-md-5 col-lg-5 col-xl-5">
                            <div className="textwidget">
                                {logo}
                                <div className="footer-links-box">
                                    <FooterContacts />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 links-ft">
                            <div className="footer-links-box">
                                <FooterVegezat />
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 links-ft">
                            <div className="footer-links-box">
                                <FooterLinks />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-button">
                    <ToTop />
                </div>
            </div>
            <footer className="home3 footer wf100">
                <div className="container">
                    <div className="footer__bottom">
                        <div className="site-footer__copyright">
                            Copyright ©
                            {' '}
                            {getCurrentYear()}
                            {' '}
                            <FormattedMessage id="footer.copyright" defaultMessage="Kërko" />
                            {' '}
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Footer);
