// react
import React, { Fragment } from 'react';

// application
import NavPanel from './NavPanel';
import Topbar from './Topbar';

function Header() {
    const bannerSection = (
        <Fragment>
            <Topbar />
        </Fragment>
    );
    const navSection = (
        // <div className="site-header__nav-panel">
            <NavPanel layout="default" />
    );

    return (
        <div className="site-header">
            {bannerSection}
            {navSection}
        </div>
    );
}

export default Header;
