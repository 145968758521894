// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobilePanel from './mobile/mobilePanel';
// import BlockContats from './blocks/BlockContats'
// data stubs
import theme from '../data/theme';

function Layout(props) {
    const {
        match,
        headerLayout,
        homeComponent,
        showslider,
        locale,
    } = props;

    let title
    if (locale === 'sq') {
        title = (
            <title>{theme.name_shq}</title>
        )
    } else if (locale === 'en') {
        title = (
            <title>{theme.name_en}</title>
        )
    } else if (locale === 'sr') {
        title = (
            <title>{theme.name_sr}</title>
        )
    }

    return (
        <React.Fragment>
            <Helmet>
                {title}
                <meta name="description" content="Ministria e Mbrojtjes" />
                <meta name="title" content="Ministria e Mbrojtjes" />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <MobilePanel layout={headerLayout} />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} showslider={showslider} />
                </header>

                <div className="site__body">
                    <Switch>
                        <Route exact path={`${match.path}`} component={homeComponent} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    headerLayout: PropTypes.oneOf(['default']),
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Layout);
