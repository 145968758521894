// react
import React, {
    useState,
    useEffect,
    Fragment,
} from 'react';

import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';
import VideoBoxBallin from '../cms_components/videoGalery/VideoBoxBallin';
import FotoBoxBallin from '../cms_components/fotogalery/FotoBoxBallin';

function GaleryHome(props) {
    const { locale } = props;
    const [photoBox, setPhotoBox] = useState([]);
    const [videoBox, setVideoBox] = useState([]);
    useEffect(() => {
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=1&Layout=default&Gjuha=${locale}&take=1`).then((response) => {
            setPhotoBox(response.data.galeriaLista);
        });
        getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=2&Layout=default&Gjuha=${locale}&take=1`).then((response) => {
            setVideoBox(response.data.galeriaLista);
        });
    }, []);

    return (
        <Fragment>
            {(videoBox.length > 0 || photoBox.length > 0) ? (
                <div className="row section-gallery">
                    {photoBox.length > 0 ? (
                        <div className="col-md-6 col-sm-6">
                            <div className="ch-box">
                                <FotoBoxBallin layout="default" />
                            </div>
                        </div>
                    ) : ('')}
                    {videoBox.length > 0 ? (
                        <div className="col-md-6 col-sm-6">
                            <div className="ch-box video-box">
                                <VideoBoxBallin layout="default" />
                            </div>
                        </div>
                    ) : ('')}
                </div>
            ) : ('')}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(GaleryHome);
