import React, { useMemo } from 'react';
// import { Helmet } from 'react-helmet-async';

// import { FormattedMessage } from 'react-intl';
import BlockHomeBoxes from '../blocks/BlockHomeBoxes';
import BlockPosts from '../blocks/BlockPosts';
import BlockSlideShow from '../blocks/BlockSlideShow';
// import Kalendari from '../cms_components/sidebar/Kalendari';
import BlogSideBar from '../cms_components/sidebar/BlogSidebar'
import GaleryHome from './GaleryHome';
// import BallinaBoxes from '../blocks/newBlocks/BallinaBoxes';
function HomePageOne() {
    return (
        <React.Fragment>
            <div className="row rreshtiii">
                <div className="col-md-12 kolonaa">
                    {useMemo(() => <BlockSlideShow />, [])}
                </div>
            </div>

            {/* {useMemo(() => <BallinaBoxes layout="default" />, [])} */}

            <div className="container box-shadow">

                <div className="row">
                    <div className="col-xl-9 col-lg-9">
                        {useMemo(() => <BlockPosts title="Latest News" layout="grid-nl" />, [])}
                        {useMemo(() => <BlockHomeBoxes />, [])}
                        {useMemo(() => <GaleryHome />, [])}
                    </div>
                    <div className="col-xl-3 col-lg-3">
                        {useMemo(() => <BlogSideBar />, [])}
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default HomePageOne;
