// react
import React, { useState, useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// application
import PageHeader from '../../shared/PageHeader';
import NewsCard from './NewsCard';
import BlogSidebar from '../sidebar/BlogSidebar';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import PostLoader from '../../blocks/PostLoader';
import PageSlider from '../page/PageSlider';
import '@formatjs/intl-datetimeformat/polyfill-force';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/add-all-tz';
import '@formatjs/intl-datetimeformat/locale-data/sq';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/sr-Latn';
// import { Check9x7Svg } from '../../../svg';

function News(props) {
    const { layout, sidebarPosition } = props;
    let { locale } = props;
    const params = useParams();
    const [post, setPost] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [NewsCategories, setNewsCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(0);
    const [loader, setLoader] = useState(false);

    const [search, setSearch] = useState({
        searchText: '',
    });

    const [dateFrom, setDateFrom] = useState();
    const [newdateFrom, setNewDateFrom] = useState('');

    const [dateTo, setDateTo] = useState();
    const [newdateTo, setNewDateTo] = useState('');

    const handleChange = (skip = 0, category = 0) => {
        setLoader(true);
        const date = params.slug == null ? '' : `&date=${params.slug}`;
        const searchfilter = search.searchText !== '' ? `&searchText=${search.searchText}` : '';
        const filterFrom = newdateFrom !== '' ? `&DateFrom=${newdateFrom}` : '';
        const filterTo = newdateTo !== '' ? `&DateTo=${newdateTo}` : '';
        getData(`News/GetNews?Gjuha=${locale}&PostimiKategoriaID=${category}&skip=${skip}&take=10&TitulliLength=120&PermbajtjaLength=170${date}${searchfilter}${filterFrom}${filterTo}`).then((response) => {
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
            setLoader(false);
        });
    }
    useEffect(() => {
        getData(`News/GetNewsCategories?Gjuha=${locale}`).then((response) => {
            setNewsCategories(response.data);
        });
        handleChange();
    }, [params.slug]);

    const handlePageChange = (CurrentPage, categoryupdated) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 10) - 10;
        if (categoryupdated === undefined) {
            handleChange(skip, currentCategory);
        } else {
            handleChange(skip, categoryupdated);
        }
    };

    const handleCategoryChange = (e) => {
        setCurrentCategory(e.target.value);
        handlePageChange(1, e.target.value);
    }

    const handleChangetext = (e) => {
        e.preventDefault();
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    }

    const handleDateFrom = (value) => {
        if (value === null) {
            setDateFrom(value);
            setNewDateFrom('');
        } else {
            const newValue = moment(value).format('YYYY-MM-DD');
            setDateFrom(value);
            setNewDateFrom(newValue);
        }
    }

    const handleDateTo = (value) => {
        if (value === null) {
            setDateTo(value);
            setNewDateTo('');
        } else {
            const newValue = moment(value).format('YYYY-MM-DD');
            setDateTo(value);
            setNewDateTo(newValue);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePageChange(1);
    }

    let sidebarStart;
    let sidebarEnd;
    const template = 'default';
    const sidebar = <BlogSidebar layout={template} position={sidebarPosition} />;

    let searchInput;
    let categorySelect;
    if (locale === 'sq') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Kërko"
                onChange={handleChangetext}
                className="form-control form-docs search-filter"
            />
        )
        categorySelect = (
            <option value="0">Të gjitha</option>
        )
    } else if (locale === 'en') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Search"
                onChange={handleChangetext}
                className="form-control form-docs search-filter"
            />
        )
        categorySelect = (
            <option value="0">All</option>
        )
    } else if (locale === 'sr') {
        searchInput = (
            <input
                type="text"
                id="searchText"
                name="searchText"
                placeholder="Pretraga"
                onChange={handleChangetext}
                className="form-control form-docs search-filter"
            />
        )
        categorySelect = (
            <option value="0">Sve</option>
        )
    }

    if (locale === 'sr') {
        locale = 'sr-Latn';
    }

    if (sidebarPosition === 'start') {
        sidebarStart = <div className="col-12 col-lg-3 order-1 order-lg-0">{sidebar}</div>;
    } else if (sidebarPosition === 'end') {
        sidebarEnd = (
            <div
                className="col-12 col-lg-3"
            >
                {NewsCategories && NewsCategories.length > 1 && (
                    <div
                        className="block-sidebar--position--end"
                        onChange={handleCategoryChange}
                    >
                        <div className="news-category-box category-dekstop">
                            <h4 className="widget__title"><FormattedMessage id="News.kategorite" defaultMessage="Kategoritë" /></h4>
                            <select className="form-control form-control-sm form-docs category-select" onChange={handleCategoryChange}>
                                {categorySelect}
                                {NewsCategories.map((kategoria, index) => <option key={index} value={kategoria.postimetKategoriaId}>{kategoria.postimetKategoriaPershkrimi}</option>)}
                            </select>
                        </div>
                    </div>
                )}
                {sidebar}
            </div>
        );
    }

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item news-list">
                <NewsCard post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <PageSlider />
            <div className="container box-shadow">
                <PageHeader header={<FormattedMessage id="News.name" defaultMessage="Lajmet" />} shfaqbreadcrumb={false} />
                <div className="row">
                    {sidebarStart}
                    <div className="col-12 col-lg-9 block-news-section">
                        <div className="block">
                            <div className="posts-view">
                                <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                    <div className="lajmet-list__body">
                                        <div className="news-filter-grid">
                                            <div className="docs-grid datepicker dateFrom">
                                                <h5><FormattedMessage id="from" defaultMessage="Prej" /></h5>
                                                <DatePicker name="DateFrom" locale={locale} onChange={handleDateFrom} value={dateFrom} format="dd/MM/yyyy" />
                                            </div>
                                            <div className="docs-grid datepicker dateTo">
                                                <h5><FormattedMessage id="to" defaultMessage="Deri" /></h5>
                                                <DatePicker name="DateTo" locale={locale} onChange={handleDateTo} value={dateTo} format="dd/MM/yyyy" />
                                            </div>
                                            <div className="docs-grid">
                                                {searchInput}
                                            </div>
                                            <div className="docs-grid">
                                                <button
                                                    id="submit"
                                                    type="submit"
                                                    className="btn btn-primary docs-search-btn"
                                                    onClick={handleSubmit}
                                                >
                                                    <FormattedMessage id="Search.name" defaultMessage="Kërko" />
                                                </button>
                                            </div>
                                        </div>

                                        {NewsCategories && NewsCategories.length > 1 && (
                                            <select className="form-control form-control-sm form-docs category-mobile" onChange={handleCategoryChange}>
                                                {categorySelect}
                                                {NewsCategories.map((kategoria, index) => <option key={index} value={kategoria.postimetKategoriaId}>{kategoria.postimetKategoriaPershkrimi}</option>)}
                                            </select>
                                        )}
                                        {
                                            loader === true ? <PostLoader /> : ''
                                        }
                                        {postsList}
                                    </div>
                                </div>
                                <div className="posts-view__pagination">
                                    {
                                        post.length > 0 ? (
                                            <div className="posts-view__pagination">
                                                <Pagination
                                                    current={CurrentPage}
                                                    siblings={2}
                                                    total={totalPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {sidebarEnd}
                </div>
            </div>
        </React.Fragment>
    );
}

News.propTypes = {
    /**
     * blog layout
     * one of ['classic', 'grid', 'list'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

News.defaultProps = {
    layout: 'list',
    sidebarPosition: 'end',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(News);
