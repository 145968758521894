import React, { useState, useEffect, Fragment } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getData } from '../../../services/fetch-service';

function VideoBoxBallin(props) {
    const { layout, locale } = props;
    const [videoBox, setVideoBox] = useState([]);
    const [page, setPage] = useState([]);
    const [url, setUrl] = useState('');
    if (layout === 'default') {
        useEffect(() => {
            getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=2&Layout=${layout}&Gjuha=${locale}&take=1`).then((response) => {
                setVideoBox(response.data.galeriaLista);
                setPage(response.data.page[0]);
                setUrl(`/VideoGalery/${response.data.page[0].pageId}`);
            });
        }, []);
    } else {
        useEffect(() => {
            getData(`Galery/GetGaleries?MediaGaleriaKategoriaID=2&Layout=${layout}&Gjuha=${locale}&take=1`).then((response) => {
                setVideoBox(response.data.galeriaLista);
                setPage(response.data.page[0]);
                setUrl(`/${layout}/VideoGalery/${response.data.page[0].pageId}`);
            });
        }, []);
    }

    const boxes = videoBox.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const sourceImage = `${path}${item.media?.mediaEmri}${item.media?.mediaEx}`;
        const image = `<video controls><source src="${sourceImage}"></video>`
        return (
            <div className="ch-thumb videoGaleryHome" key={index} dangerouslySetInnerHTML={{ __html: item.media?.otherSource === null ? image : item.media?.otherSource }} />
        );
    });

    return (
        <Fragment>
            <div className="ch-thumb videoGaleryHome">
                {boxes}
            </div>
            <div className="ch-txt">
                <h5><Link to={url}>{page.pageName}</Link></h5>
            </div>
        </Fragment>
    );
}

VideoBoxBallin.propTypes = {
    layout: PropTypes.oneOf(['default', 'thesari', 'buxheti', 'tatimiNeProne', 'partneriteti', 'socialet', 'puna', 'raportimet', 'pensionet', 'punesimet']),
};

VideoBoxBallin.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(VideoBoxBallin);
