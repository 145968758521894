// react
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getData } from '../../services/fetch-service';

function FooterContacts(props) {
    const { locale } = props;
    const [contact, setContact] = useState([]);
    useEffect(() => {
        getData(`Contact/GetContacts?PageID=1&Gjuha=${locale}`).then((response) => {
            setContact(response.data[0]);
        })
    }, []);
    return (
        <div className="site-footer__widget footer-contacts">
            <ul className="footer-contacts__contacts">
                <li>
                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon fas fa-university" />
                        <a rel="noreferrer" target="_blank" href={`https://www.google.com/maps/@${contact.gps}`}>
                            <FormattedMessage id="adresa" defaultMessage="Adresa" />
                            <p>
                                {contact.adresa}
                            </p>
                        </a>
                    </div>
                </li>
                <li>
                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon fas fas fa-phone" />
                        <a href={`tel:${contact.telefoni}`}>
                            <FormattedMessage id="tel" defaultMessage="Tel" />
                            <p>{contact.telefoni}</p>
                        </a>
                    </div>
                </li>
                <li>
                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon far fa-envelope" />
                        <a href={`mailto:${contact.emaili}`}>
                            <FormattedMessage id="email" defaultMessage="Email" />
                            <p>
                                {contact.emaili}
                            </p>
                        </a>
                    </div>
                </li>
                {/* <li>

                    <div className="footer-contact-text">
                        <i className="footer-contacts__icon far fa-clock" />
                        <p>Mon-Fri 08:00am - 16:00pm</p>
                    </div>

                </li> */}
            </ul>
        </div>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterContacts);
