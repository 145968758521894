import React, { Fragment } from 'react';

// third-party
import MobileMenu from './MobileMenu';

function mobilePanel() {
    const links = (
        <Fragment>
            <MobileMenu layout="default" />
        </Fragment>
    );

    return (
        <Fragment>
            {links}
        </Fragment>
    );
}

export default mobilePanel;
