// react
import React, { useState, useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

// application
// import PageHeader from '../../shared/PageHeader';
import PageDocsFilterContent from './PageDocsFilterContent';
import BlogSidebar from '../sidebar/BlogSidebar';
import { getData } from '../../../services/fetch-service';
import PageSlider from './PageSlider';
import getMenuLocation from './getMenuLocation';

function PageDocsFilter(props) {
    const { layout, sidebarPosition } = props;
    const params = useParams();
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    // const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [menuLocationId, setmenuLocationId] = useState();
    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            // setBreadcrumbs(response.data);
            setmenuLocationId(response.data[0].menuLocationGroupID);
        });
    }, [url]);
    let content;
    const template = getMenuLocation(menuLocationId)
    if (layout === 'classic') {
        const sidebar = <BlogSidebar layout={template} position={sidebarPosition} />;

        let sidebarStart;
        let sidebarEnd;

        if (sidebarPosition === 'start') {
            sidebarStart = <div className="col-12 col-lg-3 order-1 order-lg-0">{sidebar}</div>;
        }
        if (sidebarPosition === 'end') {
            sidebarEnd = <div className="col-12 col-lg-3">{sidebar}</div>;
        }

        content = (
            <div className="row">
                {sidebarStart}
                <div className="col-12 col-lg-9">
                    <PageDocsFilterContent layout={layout} />
                </div>
                {sidebarEnd}
            </div>
        );
    } else if (layout === 'full') {
        content = (
            <div className="row justify-content-center">
                <div className="col-md-12 col-lg-8 col-xl-8">
                    <PageDocsFilterContent layout={layout} />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {/* <Helmet>
                <title>{`Blog Post Page — ${theme.name}`}</title>
            </Helmet> */}
            <PageSlider />
            <div className="container box-shadow">{content}</div>

        </React.Fragment>
    );
}

PageDocsFilter.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

PageDocsFilter.defaultProps = {
    layout: 'classic',
    sidebarPosition: 'end',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PageDocsFilter);
