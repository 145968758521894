import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// application
import PageHeader from '../../shared/PageHeader';
import SearchContent from './SearchContent';
// import SearchSideMenu from './SearchSideMenu';
import BlogSidebar from '../sidebar/BlogSidebar';
import PageSlider from '../page/PageSlider';

function Search(props) {
    const { layout, sidebarPosition } = props;
    let content;
    if (layout === 'classic') {
        // const sidebar = <SearchSideMenu position={sidebarPosition} />;
        const sidebar = <BlogSidebar position={sidebarPosition} />;

        let sidebarStart;
        let sidebarEnd;

        if (sidebarPosition === 'start') {
            sidebarStart = <div className="col-12 col-lg-3 order-1 order-lg-0">{sidebar}</div>;
        }
        if (sidebarPosition === 'end') {
            sidebarEnd = <div className="col-12 col-lg-3">{sidebar}</div>;
        }
        content = (
            <div className="row">
                {sidebarStart}
                <div className="col-12 col-lg-9">
                    <PageHeader header={<FormattedMessage id="Search.name" defaultMessage="Kërko" />} shfaqbreadcrumb={false} />
                    <SearchContent layout={layout} />
                </div>
                {sidebarEnd}
            </div>
        );
    } else if (layout === 'full') {
        content = (
            <div className="row justify-content-center">
                <div className="col-md-12 col-lg-8 col-xl-8">
                    <SearchContent layout={layout} />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <PageSlider />
            <div className="container box-shadow">
                {content}
            </div>

        </React.Fragment>
    );
}

Search.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

Search.defaultProps = {
    layout: 'classic',
    sidebarPosition: 'end',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Search);
