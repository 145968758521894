// react
import React, { useState, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
// import AppLink from '../shared/AppLink';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';
import { getData } from '../../services/fetch-service';
// import HowDoI from '../header/howDoI';

function MobileMenu(props) {
    const { locale } = props;
    const {
        closeMobileMenu,
        localeChange: changeLocale,
        mobileMenuState,
    } = props;

    const [mobileMenuLinks, setAllLinks] = useState([]);
    // const [value, toggleValue] = useToggle(false);
    // const [howdoI, setHowDoI] = useState([]);
    // const [toplinks, setTopbar] = useState([]);

    const refreshPage = ((lang) => {
        changeLocale(lang);
        window.location.reload(false);
        // closeMobileMenu();
    });

    useEffect(() => {
        getData(`Home/GetMenus?LocationMenuID=1&Gjuha=${locale}`).then((response) => {
            setAllLinks(response.data);
        });
        // getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=22`).then((response) => {
        //     setTopbar(response.data);
        // });
    }, []);

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.hasChild === true) {
            closeMobileMenu();
        } else {
            closeMobileMenu();
        }
    };

    // const topbarLinks = toplinks.map((item, index) => {
    //     return (
    //         <div key={index} className="topbar-box-content">
    //             <div className="nav-links__item-topbar">
    //                 <Link className="topbar-item" to={item.url}>
    //                     {item.pageName}
    //                 </Link>
    //             </div>
    //         </div>
    //     );
    // });

    return (
        <div className={classes}>

            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
                    {/* <MobileLinks links={toplinks} onItemClick={handleItemClick} /> */}
                </div>

                <div className="mobilemenu__language">
                    <button
                        className="mobile-links__item-button"
                        type="button"
                        onClick={() => refreshPage('sq')}
                    >
                        SQ
                    </button>
                    <button
                        className="mobile-links__item-button"
                        type="button"
                        onClick={() => refreshPage('en')}
                    >
                        EN
                    </button>
                    <button
                        className="mobile-links__item-button"
                        type="button"
                        onClick={() => refreshPage('sr')}
                    >
                        SR
                    </button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    locale: state.locale,
});

MobileMenu.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
