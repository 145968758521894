// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

// import calendar from '../../../img/news/calendar.png';

function FotoGaleryCard(props) {
    const { post, layout } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${post.media?.mediaEmri}${post.media?.mediaEx}`;
    const cardClasses = classNames(
        'post-card galery-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-nl-4', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'grid-nl-4', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    return (
        <div className={cardClasses}>
            <div className="post-card__image galery-img">
                <Link to={post.url}>
                    {/* <img src={post.media?.otherSource === null ? image : post.media?.otherSource} alt="" /> */}
                    <LazyLoadImage
                        alt=""
                        effect="blur"
                        placeholderSrc="/images/blur/solid.jpg"
                        src={post.media?.otherSource === null ? image : post.media?.otherSource}
                    />
                </Link>
            </div>
            <div className="post-card__info galery-info">
                <div className="post-card__name">
                    <Link to={post.url}>{post.mediaGaleriaPershkrimi}</Link>
                </div>
            </div>
        </div>
    );
}

FotoGaleryCard.propTypes = {
    /**
     * post data object
     */
    // post: PropTypes.array,
    /**
     * post card layout
     * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
     */
    layout: PropTypes.oneOf(['grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};

FotoGaleryCard.defaultProps = {
    layout: 'grid-nl-4',
};

export default FotoGaleryCard;
